import React from "react"
import { Link, Asset } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"
import useLatestListingsQuery from "../../../hooks/use-latest-listings-query"
import PropertyCard from "../../cards/property"
import sal from "sal.js"

const LatestListing = ({ title }) => {

  const { sale, sold } = useLatestListingsQuery()
  const [filter, setFilter] = React.useState('all')

  // sort sold by soldDate
  let sortedSale = sale?.sort((a, b) => new Date(b.marketingLiveDate) - new Date(a.marketingLiveDate))
  let sortedSold = sold?.sort((a, b) => new Date(b.soldDate) - new Date(a.soldDate))

  const sortedListings = sortedSale.concat(sortedSold)

  const properties = {
    'all': sortedListings,
    'sale': sortedSale,
    'sold': sortedSold,
  }[filter]

  React.useEffect(() => {
    sal()
  }, [filter])

  return (
    <section className='latest-listing'>
      <div className='latest-listing__inner'>
        <div className='latest-listing__header'>
          { title && <h2 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h2> }
          <ul>
            <li data-sal='slide-up' data-sal-duration='1000'><button className={`${filter === 'all' ? 'active' : ''}`} onClick={() => setFilter('all')}>All</button></li>
            <li data-sal='slide-up' data-sal-duration='1000'><button className={`${filter === 'sale' ? 'active' : ''}`} onClick={() => setFilter('sale')}>Sale</button></li>
            <li data-sal='slide-up' data-sal-duration='1000'><button className={`${filter === 'sold' ? 'active' : ''}`} onClick={() => setFilter('sold')}>Sold</button></li>
          </ul>
          <div className='latest-listing__button' data-sal='slide-up' data-sal-duration='1000'>
            <Link to='/for-sale/' className='btn btn--black'>
              <span>View</span>More<RightArrow />
            </Link>
          </div>
        </div>
        <div className='latest-listing__items'>
          { properties.map((property, index) => (
            <PropertyCard key={index} {...property} />
          )) }
        </div>
      </div>
    </section>
  )
}

export default LatestListing

export const query = graphql`
  fragment LatestListingProps on DatoCmsLatestListing {
    ... on DatoCmsLatestListing {
      id
      title
    }
  }
`
