import React, {useRef} from 'react'
import { MediaItem, Link, Asset} from '../../../plugins/gatsby-plugin-atollon'
import { Sold } from "../icons";

const PropertyCard = (props) => {

  //if props empty object return null
  if (!props) return null

  return (
    <Link to={props.permalink} className={`card card--property${props.for === 'sold' ? ' card--sold' : ''}`} data-sal='slide-up' data-sal-duration='1000'>
      <div className='card__image'>
        {props.images?.length > 0 && props.images[0].primary?.length > 0 ? (
          <Link to={props.permalink}>
            <MediaItem item={props.images[0].primary}/>
          </Link>
        ) : <Asset asset={props.thumbnail} />}
        {props.for === 'sold' && <span className='card__tag'><Sold /></span>}
      </div>
      <div className='card__content'>
        <div className='card__title'>
          {!props.addressComponents && props.address && <h4>{props.address}</h4>}
          {props.addressComponents?.length > 0 && <h4>
            <strong>{props.addressComponents[0]?.unit && `${props.addressComponents[0]?.unit} / `}
            {props.addressComponents[0]?.streetNumber} {props.addressComponents[0]?.street + ''}</strong><br/>
            {props.addressComponents[0]?.city} {props.addressComponents[0]?.postCode}
          </h4>}
        </div>
        <div className='card__stats'>
          <ul>
            {props.beds > 0 && <li><span>Bedrooms</span><span>{props.beds}</span></li>}
            {props.baths > 0 && <li><span>Bathrooms</span><span>{props.baths}</span></li>}
            {props.allCarSpaces > 0 && <li><span>Parking</span><span>{props.allCarSpaces}</span></li>}
          </ul>
        </div>
      </div>
    </Link>
  )
}

export default PropertyCard