import React from "react"
import { Link, Asset } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"
import StructuredText from "../custom-structured-text-renderer"

const DownloadCta = ({ title, subtitle, richContent, button, image }) => {

    return (
      <section className='cta cta--download'>
        <div className="cta__inner">
          <div className='cta__content'>
            { title && <h3 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h3> }
            { subtitle && <h4 data-sal='slide-up' data-sal-duration='1000'>{parse(subtitle)}</h4> }
            { richContent && <div className="cta__text" data-sal='slide-up' data-sal-duration='1000'><StructuredText data={richContent} /></div> }
            { button?.length > 0 && button[0].link?.length > 0 &&
              <Link
                className="btn btn--black"
                data-sal='slide-up' data-sal-duration='1000'
                to={button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink}
              >
                {button[0].title}
                <RightArrow />
              </Link>
            }
          </div>
          { image && 
            <div className='cta__image' data-sal='slide-up' data-sal-duration='1000'>
              <Asset asset={image} />
            </div>
          }
        </div>
      </section>
    )
}

export default DownloadCta

export const query = graphql`
  fragment DownloadCtaProps on DatoCmsDownloadCta {
    ... on DatoCmsDownloadCta {
      id
      title
      subtitle
      richContent {
        value
      }
      button {
        ...LinkWithTitleProps
      }
      image {
        ...InternalAssetProps
      }
    }
  }
`
