import React from "react";

export const Logo = ({color = '#FFFFFF'}) => (
  <svg width="239" height="20" viewBox="0 0 239 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.0466 19.2305C52.5898 18.6256 52.1248 18.0455 51.6598 17.4737C51.6517 17.4654 51.6436 17.4489 51.6354 17.4406H23.2318L46.3222 12.173C46.2977 12.1565 46.2814 12.1316 46.257 12.115C45.7349 11.6427 45.2046 11.1952 44.6826 10.7643L22.4161 15.8164L38.6246 6.03001L38.0373 5.68194C37.6375 5.44994 37.246 5.23448 36.8545 5.01902H36.8463L20.6704 14.7806L30.6208 1.91434C30.3271 1.80661 29.9958 1.67183 29.7103 1.57239C29.4166 1.48125 29.1311 1.39011 28.8456 1.29892C28.8211 1.29068 28.8048 1.28238 28.7804 1.27409L18.9493 14.068V14.0762L18.9476 14.0782L18.9493 14.068L22.1295 0.0994347C21.8767 0.074601 21.6238 0.0580119 21.379 0.0414228C21.0283 0.0165891 20.6857 0 20.3594 0H20.3513L17.1628 14.0762L13.7042 0.867449C13.1413 1.0249 12.5184 1.23257 11.9637 1.45632L15.5151 14.7557L6.83578 4.42241C6.37898 4.79532 5.93029 5.18476 5.50615 5.60739L14.112 15.8413H14.1202L14.1237 15.8454L14.112 15.8413L1.80889 10.6451C1.71918 10.8523 1.62941 11.0677 1.54786 11.2832C1.41731 11.6229 1.18719 12.1087 1.08114 12.465L13.2881 17.4406H0.0928544V17.4737C0.0278314 18.0455 0 18.6256 0 19.2305H53.0466Z" fill="#FF6817"/>
    <path d="M61.8452 2.26953H65.1792V16.1647H75.1893V19.2148H61.8452V2.26953Z" fill="black"/>
    <path d="M100.166 10.7423C100.166 15.8253 96.4983 19.5545 91.4932 19.5545C86.4881 19.5545 82.8199 15.8253 82.8199 10.7423C82.8199 5.65931 86.4881 1.93018 91.4932 1.93018C96.4983 1.93018 100.166 5.65931 100.166 10.7423ZM96.664 10.7423C96.664 7.52247 94.4957 5.15002 91.4932 5.15002C88.4907 5.15002 86.3224 7.52247 86.3224 10.7423C86.3224 13.9622 88.4907 16.3346 91.4932 16.3346C94.4957 16.3346 96.664 13.9622 96.664 10.7423Z" fill="black"/>
    <path d="M133.437 19.2148L140.276 2.26953H145.281L152.12 19.2148H148.515L147.148 15.8252H138.342L136.972 19.2148H133.437ZM145.913 12.7751L142.911 5.31962H142.577L139.574 12.7751H145.913Z" fill="black"/>
    <path d="M157.053 2.26953H173.734V5.31962H167.063V19.2148H163.726V5.31962H157.053V2.26953Z" fill="black"/>
    <path d="M183.23 19.2148V2.26953H186.567V19.2148H183.23Z" fill="black"/>
    <path d="M213.571 10.7423C213.571 15.8253 209.903 19.5545 204.898 19.5545C199.893 19.5545 196.224 15.8253 196.224 10.7423C196.224 5.65931 199.893 1.93018 204.898 1.93018C209.903 1.93018 213.571 5.65931 213.571 10.7423ZM210.068 10.7423C210.068 7.52247 207.9 5.15002 204.898 5.15002C201.895 5.15002 199.727 7.52247 199.727 10.7423C199.727 13.9622 201.895 16.3346 204.898 16.3346C207.9 16.3346 210.068 13.9622 210.068 10.7423Z" fill="black"/>
    <path d="M225.653 7.52231V19.2148H222.319V2.26953H225.322L235.663 13.962V2.26953H239V19.2148H235.998L225.653 7.52231Z" fill="black"/>
    <path d="M122.472 12.7785C121.747 14.8609 119.984 16.2895 117.692 16.2895C114.69 16.2895 112.521 13.917 112.521 10.6972C112.521 7.47731 114.69 5.10486 117.692 5.10486C119.986 5.10486 121.75 6.5096 122.474 8.61583H126.134C125.264 4.62136 121.995 1.88501 117.692 1.88501C112.69 1.88501 109.019 5.61414 109.019 10.6972C109.019 15.7802 112.69 19.5093 117.692 19.5093C121.995 19.5093 125.264 16.773 126.134 12.7785H122.472Z" fill="black"/>
  </svg>
)

export const Sun = ({color = '#000000'}) => (
  <svg width="74" height="27" viewBox="0 0 74 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M73.2453 26.5526C72.6145 25.7174 71.9725 24.9164 71.3305 24.1269C71.3193 24.1155 71.308 24.0926 71.2967 24.0811H32.0784L63.9605 16.8079C63.9268 16.7851 63.9042 16.7507 63.8704 16.7278C63.1496 16.0756 62.4175 15.4578 61.6967 14.8628L30.9521 21.8386L53.332 8.32596L52.5211 7.84536C51.9691 7.52503 51.4285 7.22753 50.8879 6.93004H50.8766L28.5418 20.4084L42.2807 2.64324C41.8753 2.49449 41.4178 2.30838 41.0236 2.17109C40.618 2.04524 40.2239 1.9194 39.8297 1.79349C39.7959 1.78211 39.7733 1.77066 39.7396 1.7592L26.1653 19.4244V19.4358L26.1629 19.4385L26.1653 19.4244L30.5564 0.137295C30.2073 0.103006 29.8581 0.0801002 29.5202 0.0571947C29.0359 0.0229055 28.5628 0 28.1123 0H28.1011L23.6986 19.4358L18.9231 1.19773C18.1459 1.41513 17.2858 1.70187 16.5199 2.01082L21.4235 20.374L9.43951 6.10627C8.80877 6.62116 8.18925 7.15888 7.60361 7.74242L19.4862 21.8729H19.4975L19.5023 21.8786L19.4862 21.8729L2.49861 14.6983C2.37474 14.9843 2.25079 15.2818 2.13818 15.5793C1.95794 16.0484 1.6402 16.7192 1.49377 17.2112L18.3486 24.0811H0.129186V24.1269C0.0394049 24.9164 0.000976562 25.7174 0.000976562 26.5526H73.2453Z" fill={color}/>
  </svg>
)

export const Instagram = ({color = '#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34305 1.71423H10.6581C12.654 1.71423 14.2861 3.3262 14.2861 5.29546V10.5961C14.2861 12.5658 12.654 14.1773 10.6581 14.1773H5.34305C3.34712 14.1773 1.71472 12.5658 1.71472 10.5961V5.29546C1.71472 3.3262 3.3471 1.71423 5.34305 1.71423ZM7.96672 4.58086C9.90627 4.58086 11.4802 6.15479 11.4802 8.09434C11.4802 10.0341 9.90627 11.6078 7.96672 11.6078C6.02669 11.6078 4.453 10.0341 4.453 8.09434C4.453 6.15479 6.02669 4.58086 7.96672 4.58086ZM7.9664 5.76753C9.25078 5.76753 10.2932 6.8097 10.2932 8.0943C10.2932 9.3789 9.25076 10.4213 7.9664 10.4213C6.68156 10.4213 5.63939 9.37892 5.63939 8.0943C5.63939 6.8097 6.68156 5.76753 7.9664 5.76753ZM11.3659 4.09398C11.681 4.09398 11.9364 4.34941 11.9364 4.66425C11.9364 4.97932 11.681 5.23476 11.3659 5.23476C11.0511 5.23476 10.7956 4.97932 10.7956 4.66425C10.7956 4.34941 11.0511 4.09398 11.3659 4.09398ZM5.77761 2.73237H10.2245C11.8942 2.73237 13.2598 4.09122 13.2598 5.75171V10.2208C13.2598 11.8813 11.8942 13.2399 10.2245 13.2399H5.77761C4.10787 13.2399 2.74207 11.8813 2.74207 10.2208V5.75167C2.74207 4.09122 4.10787 2.73237 5.77761 2.73237Z" fill="white"/>
  </svg>)

export const Facebook = ({color = '#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.13147 14.8571H6.09097V7.71337H4.57166V5.25187H6.09097V3.77407C6.09097 1.7661 6.94841 0.571411 9.38601 0.571411H11.4149V3.03381H10.1468C9.19789 3.03381 9.13519 3.37779 9.13519 4.01976L9.13101 5.25187H11.4288L11.1599 7.71337H9.13101L9.13147 14.8571Z" fill="white"/>
  </svg>)

export const Spotify = ({color = '#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.71387 7.98064C1.71387 4.51983 4.51954 1.71423 7.98035 1.71423L7.98028 1.71453C11.4415 1.71453 14.2469 4.52006 14.2469 7.98064C14.2469 11.4416 11.4415 14.247 7.98035 14.247C4.51954 14.247 1.71387 11.4416 1.71387 7.98064ZM10.3167 10.8817C10.5008 10.9947 10.7418 10.9363 10.854 10.7522C10.967 10.5681 10.9086 10.3279 10.7246 10.215C9.07277 9.2055 7.0252 8.97045 4.63813 9.51604C4.4274 9.56393 4.29623 9.77345 4.34434 9.98373C4.39223 10.194 4.60176 10.3257 4.81195 10.2778C6.99325 9.77944 8.84544 9.98298 10.3167 10.8817ZM11.6214 9.04565C11.48 9.27575 11.1792 9.34834 10.9494 9.20691C9.26501 8.17134 6.69737 7.87149 4.70502 8.47627C4.44664 8.55432 4.17373 8.4087 4.09531 8.15076C4.01749 7.89237 4.16318 7.61999 4.42112 7.54142C6.69692 6.85089 9.52617 7.18538 11.4605 8.37405C11.6902 8.51548 11.7628 8.8163 11.6214 9.04573V9.04565ZM4.40711 6.54413C6.3354 5.95888 9.66758 6.06918 11.6872 7.26878H11.6875C11.9652 7.43415 12.3258 7.34234 12.4905 7.06382C12.6558 6.78568 12.5645 6.42597 12.2854 6.26067C9.96016 4.88028 6.2804 4.75045 4.06685 5.42243C3.75698 5.51656 3.58233 5.84379 3.67617 6.15359C3.77 6.46323 4.09746 6.63804 4.40711 6.54413Z" fill="white"/>
  </svg>)

export const Youtube = ({color = '#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.429 10.705V5.40962C15.429 5.40962 15.429 2.8573 12.8687 2.8573H3.13137C3.13137 2.8573 0.571899 2.8573 0.571899 5.40962V10.705C0.571899 10.705 0.571899 13.2573 3.13137 13.2573H12.8687C12.8687 13.2573 15.429 13.2573 15.429 10.705ZM10.885 8.06479L6.02112 10.9123V5.2164L10.885 8.06479Z" fill="white"/>
  </svg>)

export const Linkedin = ({color = '#FFFFFF'}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6049 2C2.71854 2 2 2.71854 2 3.6049V16.3951C2 17.2815 2.71854 18 3.6049 18H16.3951C17.2815 18 18 17.2815 18 16.3951V3.6049C18 2.71854 17.2815 2 16.3951 2H3.6049ZM6.06792 7.28163H6.0534C5.3277 7.28163 4.85721 6.74753 4.85721 6.07117C4.85721 5.38099 5.34161 4.85742 6.08183 4.85742C6.82144 4.85742 7.27621 5.37967 7.29072 6.0692C7.29072 6.74555 6.82144 7.28163 6.06792 7.28163ZM5.11625 8.1552H7.121V15.142H5.11625V8.1552ZM12.8694 15.1432H15.1427V11.2576C15.1427 9.09423 14.0233 8.08859 12.5985 8.08859C11.4501 8.08859 10.7304 8.77285 10.5974 9.25229V8.15576H8.3453C8.37433 8.73794 8.3453 15.1432 8.3453 15.1432H10.5974V11.3624L10.5971 11.2869C10.5962 11.1022 10.5953 10.9236 10.6464 10.7914C10.8012 10.3705 11.1338 9.93523 11.7319 9.93523C12.5139 9.93523 12.8694 10.5806 12.8694 11.527V15.1432Z" fill="white"/>
  </svg>
)

export const SquareFacebook = ({color = '#FFFFFF'}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6049 2C2.71854 2 2 2.71854 2 3.6049V16.3951C2 17.2815 2.71854 18 3.6049 18H16.3951C17.2815 18 18 17.2815 18 16.3951V3.6049C18 2.71854 17.2815 2 16.3951 2H3.6049ZM10.5471 17.9999H13.243L13.2426 11.5991H15.0415L15.28 9.39361H13.2426L13.2463 8.28965C13.2463 7.71444 13.3019 7.40623 14.1433 7.40623H15.2676V5.19992H13.4687C11.3074 5.19992 10.5471 6.27036 10.5471 8.0695V9.39361H9.2V11.5991H10.5471V17.9999Z" fill="white"/>
  </svg>
)

export const SquareYoutube = ({color = '#FFFFFF'}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6049 2C2.71854 2 2 2.71854 2 3.6049V16.3951C2 17.2815 2.71854 18 3.6049 18H16.3951C17.2815 18 18 17.2815 18 16.3951V3.6049C18 2.71854 17.2815 2 16.3951 2H3.6049ZM6.66084 14.208L13.8608 10.104L6.66084 5.99996L6.66084 14.208Z" fill="white"/>
  </svg>
)

export const Quote = ({color = '#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16H10.3568V8.51502C10.3568 3.50215 12.2158 0.686699 16 0V3.09013C13.9419 3.77682 13.278 5.28755 13.3444 9.13305H16V16ZM5.64315 16H0V8.51502C0 3.50215 1.85892 0.686699 5.64315 0V3.09013C3.58506 3.77682 2.92116 5.28755 2.98755 9.13305H5.64315V16Z" fill="white"/>
  </svg>)

export const Direct = ({color = '#FFFFFF'}) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L11 1M11 1V10M11 1H2" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>)

export const Plus = ({color = '#FFFFFF'}) => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99935 0.666504V12.3332M1.16602 6.49984H12.8327" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Minus = ({color = '#FFFFFF'}) => (
  <svg width="14" height="1" viewBox="0 0 14 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.16602 0.5H12.8327" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Play = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 2V18L17 10L4 2Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Floorplan = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 9V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H11M2 4V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H4M9 2H11M16 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V4M18 9V11M18 16V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H16M2 10H9C9.26522 10 9.51957 10.1054 9.70711 10.2929C9.89464 10.4804 10 10.7348 10 11V18" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)


export const Loading = ({color = '#FFFFFF'}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" fill="none" stroke='#15222D' strokeWidth="10" r="25" strokeDasharray="117.80972450961724 41.269908169872416"></circle>
  </svg>
)

export const Sold = ({color = '#FFFFFF'}) => (
  <svg width="102" height="52" viewBox="0 0 102 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="102" height="52" fill="#FF6817"/>
    <path d="M14.048 39.052C14.4 39.404 14.8547 39.712 15.412 39.976C15.9693 40.2107 16.8053 40.328 17.92 40.328C18.8587 40.328 19.7387 40.2107 20.56 39.976C21.3813 39.712 22.0853 39.3453 22.672 38.876C23.288 38.3773 23.772 37.7907 24.124 37.116C24.476 36.412 24.652 35.62 24.652 34.74C24.652 33.5667 24.2853 32.584 23.552 31.792C22.848 31 21.9533 30.296 20.868 29.68C19.812 29.064 18.6533 28.4627 17.392 27.876C16.1307 27.2893 14.9573 26.6 13.872 25.808C12.816 24.9867 11.9213 24.0187 11.188 22.904C10.484 21.76 10.132 20.3373 10.132 18.636C10.132 17.404 10.352 16.304 10.792 15.336C11.232 14.368 11.848 13.5467 12.64 12.872C13.4613 12.1973 14.4293 11.684 15.544 11.332C16.6587 10.9507 17.9053 10.76 19.284 10.76C21.0733 10.76 22.7013 10.9067 24.168 11.2C25.664 11.464 26.8373 11.8013 27.688 12.212C27.688 12.7107 27.644 13.2973 27.556 13.972C27.4973 14.6173 27.4093 15.292 27.292 15.996C27.204 16.7 27.1013 17.3893 26.984 18.064C26.8667 18.7093 26.7493 19.2813 26.632 19.78H25.488L23.728 13.752C23.2 13.5173 22.5253 13.3413 21.704 13.224C20.912 13.1067 20.0613 13.048 19.152 13.048C17.656 13.048 16.4533 13.4733 15.544 14.324C14.6347 15.1453 14.18 16.304 14.18 17.8C14.18 19.0027 14.532 20.0147 15.236 20.836C15.9693 21.6573 16.8787 22.3907 17.964 23.036C19.0493 23.6813 20.208 24.312 21.44 24.928C22.7013 25.5147 23.8747 26.204 24.96 26.996C26.0453 27.788 26.94 28.7267 27.644 29.812C28.3773 30.8973 28.744 32.2467 28.744 33.86C28.744 35.18 28.4947 36.3827 27.996 37.468C27.5267 38.5533 26.8373 39.4773 25.928 40.24C25.0187 41.0027 23.904 41.5893 22.584 42C21.264 42.4107 19.768 42.616 18.096 42.616C16.248 42.616 14.5907 42.396 13.124 41.956C11.6573 41.516 10.616 41.0467 10 40.548C10.0293 40.0493 10.0733 39.448 10.132 38.744C10.22 38.04 10.308 37.3213 10.396 36.588C10.5133 35.8253 10.6307 35.092 10.748 34.388C10.8653 33.684 10.9827 33.0827 11.1 32.584H12.244L14.048 39.052Z" fill="black"/>
    <path d="M32.5418 31.088C32.5418 29.24 32.7765 27.612 33.2458 26.204C33.7445 24.7667 34.4485 23.564 35.3578 22.596C36.2672 21.5987 37.3525 20.8507 38.6138 20.352C39.9045 19.824 41.3272 19.56 42.8818 19.56C44.7005 19.56 46.2698 19.8387 47.5898 20.396C48.9098 20.9533 49.9805 21.7453 50.8018 22.772C51.6525 23.7693 52.2685 24.972 52.6498 26.38C53.0605 27.788 53.2658 29.3573 53.2658 31.088C53.2658 34.784 52.3272 37.6293 50.4498 39.624C48.5725 41.6187 46.0498 42.616 42.8818 42.616C41.1218 42.616 39.5965 42.352 38.3058 41.824C37.0152 41.2667 35.9445 40.4893 35.0938 39.492C34.2432 38.4653 33.5978 37.248 33.1578 35.84C32.7472 34.4027 32.5418 32.8187 32.5418 31.088ZM36.7658 31.088C36.7658 32.408 36.8832 33.6547 37.1178 34.828C37.3818 36.0013 37.7778 37.028 38.3058 37.908C38.8632 38.7587 39.5818 39.4333 40.4618 39.932C41.3418 40.4307 42.3978 40.68 43.6298 40.68C45.2138 40.68 46.5045 39.9027 47.5018 38.348C48.5285 36.7933 49.0418 34.3733 49.0418 31.088C49.0418 29.7387 48.9098 28.492 48.6458 27.348C48.4112 26.1747 48.0298 25.1627 47.5018 24.312C46.9738 23.432 46.2992 22.7427 45.4778 22.244C44.6565 21.7453 43.6738 21.496 42.5298 21.496C40.7992 21.496 39.4058 22.2733 38.3498 23.828C37.2938 25.3827 36.7658 27.8027 36.7658 31.088Z" fill="black"/>
    <path d="M66.3302 42.088H56.5622V40.988C57.5302 40.5187 58.4982 40.1667 59.4662 39.932V12.08L56.1222 11.728V10.584C56.8848 10.232 57.8382 9.924 58.9822 9.66C60.1262 9.396 61.2408 9.176 62.3262 9H63.4262V39.932C64.3942 40.1667 65.3622 40.5187 66.3302 40.988V42.088Z" fill="black"/>
    <path d="M84.1045 37.732C83.9579 38.3773 83.7085 38.9933 83.3565 39.58C83.0045 40.1667 82.5645 40.6947 82.0365 41.164C81.5379 41.604 80.9512 41.956 80.2765 42.22C79.6312 42.484 78.9272 42.616 78.1645 42.616C75.2605 42.616 73.0899 41.692 71.6525 39.844C70.2152 37.996 69.4965 35.1213 69.4965 31.22C69.4965 27.4653 70.4059 24.6053 72.2245 22.64C74.0725 20.6747 76.5512 19.692 79.6605 19.692C80.7459 19.692 81.5965 19.7507 82.2125 19.868C82.8285 19.956 83.5032 20.132 84.2365 20.396V12.08L80.8925 11.728V10.584C81.7432 10.232 82.7259 9.924 83.8405 9.66C84.9845 9.396 86.0699 9.176 87.0965 9H88.1965V39.58L91.1005 39.932V40.856C90.2499 41.2667 89.3699 41.6187 88.4605 41.912C87.5512 42.2053 86.6565 42.4107 85.7765 42.528H84.6765L84.2805 37.732H84.1045ZM79.0445 39.888C80.4819 39.888 81.6259 39.4333 82.4765 38.524C83.3272 37.6147 83.9139 36.3827 84.2365 34.828V22.42C83.7379 22.1267 83.1512 21.9213 82.4765 21.804C81.8019 21.6867 80.9365 21.628 79.8805 21.628C77.8859 21.628 76.3605 22.508 75.3045 24.268C74.2485 25.9987 73.7205 28.316 73.7205 31.22C73.7205 32.4227 73.8232 33.552 74.0285 34.608C74.2339 35.6347 74.5565 36.544 74.9965 37.336C75.4365 38.128 75.9792 38.7587 76.6245 39.228C77.2992 39.668 78.1059 39.888 79.0445 39.888Z" fill="black"/>
  </svg>
)

export const Calendar = ({color = '#FFFFFF'}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4168 17.5H5.00016C4.55814 17.5 4.13421 17.3244 3.82165 17.0118C3.50909 16.6993 3.3335 16.2754 3.3335 15.8333V5.83333C3.3335 5.39131 3.50909 4.96738 3.82165 4.65482C4.13421 4.34226 4.55814 4.16667 5.00016 4.16667H15.0002C15.4422 4.16667 15.8661 4.34226 16.1787 4.65482C16.4912 4.96738 16.6668 5.39131 16.6668 5.83333V10M13.3335 2.5V5.83333M6.66683 2.5V5.83333M3.3335 9.16667H16.6668M13.3335 15.8333H18.3335M15.8335 13.3333V18.3333" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const Gallery = ({color = '#FFFFFF'}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 6.66667H12.5083M10.4167 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V10.4167M2.5 13.3334L6.66667 9.16673C7.44 8.42256 8.39333 8.42256 9.16667 9.16673L12.5 12.5001M11.6667 11.6666L12.5 10.8333C13.0583 10.2966 13.7083 10.1466 14.3183 10.3833M13.3333 15.8333H18.3333M15.8333 13.3333V18.3333" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Expand = ({color = '#FFFFFF'}) => (
  <svg width="144" height="92" viewBox="0 0 144 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.875 12H20.125M20.125 12L13.625 18.5M20.125 12L13.625 5.5" stroke="black" strokeLinecap="square"/>
    <path d="M38 9L44 15L50 9" stroke="black" strokeLinecap="square"/>
    <path d="M82 15L76 9L70 15" stroke="black" strokeLinecap="square"/>
    <path d="M106.417 17.5H101C100.558 17.5 100.134 17.3244 99.8217 17.0118C99.5091 16.6993 99.3335 16.2754 99.3335 15.8333V5.83333C99.3335 5.39131 99.5091 4.96738 99.8217 4.65482C100.134 4.34226 100.558 4.16667 101 4.16667H111C111.442 4.16667 111.866 4.34226 112.179 4.65482C112.491 4.96738 112.667 5.39131 112.667 5.83333V10M109.333 2.5V5.83333M102.667 2.5V5.83333M99.3335 9.16667H112.667M109.333 15.8333H114.333M111.833 13.3333V18.3333" stroke="black" strokeLinecap="square"/>
    <path d="M136.5 6.66667H136.508M134.417 17.5H129C128.337 17.5 127.701 17.2366 127.232 16.7678C126.763 16.2989 126.5 15.663 126.5 15V5C126.5 4.33696 126.763 3.70107 127.232 3.23223C127.701 2.76339 128.337 2.5 129 2.5H139C139.663 2.5 140.299 2.76339 140.768 3.23223C141.237 3.70107 141.5 4.33696 141.5 5V10.4167M126.5 13.3334L130.667 9.16673C131.44 8.42256 132.393 8.42256 133.167 9.16673L136.5 12.5001M135.667 11.6666L136.5 10.8333C137.058 10.2966 137.708 10.1466 138.318 10.3833M137.333 15.8333H142.333M139.833 13.3333V18.3333" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 78H13M10 75V81M21 89L15 83M3 78C3 78.9193 3.18106 79.8295 3.53284 80.6788C3.88463 81.5281 4.40024 82.2997 5.05025 82.9497C5.70026 83.5998 6.47194 84.1154 7.32122 84.4672C8.1705 84.8189 9.08075 85 10 85C10.9193 85 11.8295 84.8189 12.6788 84.4672C13.5281 84.1154 14.2997 83.5998 14.9497 82.9497C15.5998 82.2997 16.1154 81.5281 16.4672 80.6788C16.8189 79.8295 17 78.9193 17 78C17 77.0807 16.8189 76.1705 16.4672 75.3212C16.1154 74.4719 15.5998 73.7003 14.9497 73.0503C14.2997 72.4002 13.5281 71.8846 12.6788 71.5328C11.8295 71.1811 10.9193 71 10 71C9.08075 71 8.1705 71.1811 7.32122 71.5328C6.47194 71.8846 5.70026 72.4002 5.05025 73.0503C4.40024 73.7003 3.88463 74.4719 3.53284 75.3212C3.18106 76.1705 3 77.0807 3 78Z" stroke="black" strokeLinecap="square" strokeLinejoin="round"/>
    <path d="M52.125 80H35.875M35.875 80L42.375 86.5M35.875 80L42.375 73.5" stroke="black" strokeLinecap="square"/>
    <path d="M76 71.875L76 88.125M76 88.125L82.5 81.625M76 88.125L69.5 81.625" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const RightChevron = ({color = '#FFFFFF'}) => (
  <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.499999 11L5.5 6L0.5 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const LeftArrow = ({color = '#FFFFFF'}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.125 12H3.875M3.875 12L10.375 18.5M3.875 12L10.375 5.5" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const RightArrow = ({color = '#FFFFFF'}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.875 12H20.125M20.125 12L13.625 18.5M20.125 12L13.625 5.5" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const DownArrow = ({color = '#FFFFFF'}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 3.875L12 20.125M12 20.125L18.5 13.625M12 20.125L5.5 13.625" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const DownChevron = ({color = '#FFFFFF'}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const UpChevron = ({color = '#FFFFFF'}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="square"/>
  </svg>
)

export const Close = ({color = '#FFFFFF'}) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.12472 9.12479L0.875142 0.875209M9.12472 0.87521L0.875141 9.12479" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Filter = ({color = '#FFFFFF'}) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50016 2.99992C7.50016 3.44195 7.67576 3.86587 7.98832 4.17843C8.30088 4.49099 8.7248 4.66659 9.16683 4.66659C9.60886 4.66659 10.0328 4.49099 10.3453 4.17843C10.6579 3.86587 10.8335 3.44195 10.8335 2.99992M7.50016 2.99992C7.50016 2.55789 7.67576 2.13397 7.98832 1.82141C8.30088 1.50885 8.7248 1.33325 9.16683 1.33325C9.60886 1.33325 10.0328 1.50885 10.3453 1.82141C10.6579 2.13397 10.8335 2.55789 10.8335 2.99992M7.50016 2.99992H0.833496M10.8335 2.99992H14.1668M2.50016 7.99992C2.50016 8.44195 2.67576 8.86587 2.98832 9.17843C3.30088 9.49099 3.7248 9.66659 4.16683 9.66659C4.60886 9.66659 5.03278 9.49099 5.34534 9.17843C5.6579 8.86587 5.8335 8.44195 5.8335 7.99992M2.50016 7.99992C2.50016 7.55789 2.67576 7.13397 2.98832 6.82141C3.30088 6.50885 3.7248 6.33325 4.16683 6.33325C4.60886 6.33325 5.03278 6.50885 5.34534 6.82141C5.6579 7.13397 5.8335 7.55789 5.8335 7.99992M2.50016 7.99992H0.833496M5.8335 7.99992H14.1668M10.0002 12.9999C10.0002 13.4419 10.1758 13.8659 10.4883 14.1784C10.8009 14.491 11.2248 14.6666 11.6668 14.6666C12.1089 14.6666 12.5328 14.491 12.8453 14.1784C13.1579 13.8659 13.3335 13.4419 13.3335 12.9999M10.0002 12.9999C10.0002 12.5579 10.1758 12.134 10.4883 11.8214C10.8009 11.5088 11.2248 11.3333 11.6668 11.3333C12.1089 11.3333 12.5328 11.5088 12.8453 11.8214C13.1579 12.134 13.3335 12.5579 13.3335 12.9999M10.0002 12.9999H0.833496M13.3335 12.9999H14.1668" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Search = ({color = '#FFFFFF'}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 15.5L10.5 10.5M0.5 6.33333C0.5 7.09938 0.650884 7.85792 0.944036 8.56565C1.23719 9.27339 1.66687 9.91645 2.20854 10.4581C2.75022 10.9998 3.39328 11.4295 4.10101 11.7226C4.80875 12.0158 5.56729 12.1667 6.33333 12.1667C7.09938 12.1667 7.85792 12.0158 8.56565 11.7226C9.27339 11.4295 9.91645 10.9998 10.4581 10.4581C10.9998 9.91645 11.4295 9.27339 11.7226 8.56565C12.0158 7.85792 12.1667 7.09938 12.1667 6.33333C12.1667 5.56729 12.0158 4.80875 11.7226 4.10101C11.4295 3.39328 10.9998 2.75022 10.4581 2.20854C9.91645 1.66687 9.27339 1.23719 8.56565 0.944036C7.85792 0.650883 7.09938 0.5 6.33333 0.5C5.56729 0.5 4.80875 0.650883 4.10101 0.944036C3.39328 1.23719 2.75022 1.66687 2.20854 2.20854C1.66687 2.75022 1.23719 3.39328 0.944036 4.10101C0.650884 4.80875 0.5 5.56729 0.5 6.33333Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Back = ({color = '#FFFFFF'}) => (
  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.7279 11.9999L1.27208 11.9999M1.27208 11.9999L12.7272 0.544732M1.27208 11.9999L12.7272 23.455" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Zoom = ({color = '#FFFFFF'}) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 8H11M8 5V11M19 19L13 13M1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788C14.8189 9.82951 15 8.91925 15 8C15 7.08075 14.8189 6.1705 14.4672 5.32122C14.1154 4.47194 13.5998 3.70026 12.9497 3.05025C12.2997 2.40024 11.5281 1.88463 10.6788 1.53284C9.82951 1.18106 8.91925 1 8 1C7.08075 1 6.1705 1.18106 5.32122 1.53284C4.47194 1.88463 3.70026 2.40024 3.05025 3.05025C2.40024 3.70026 1.88463 4.47194 1.53284 5.32122C1.18106 6.1705 1 7.08075 1 8Z"
            stroke="black" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
)