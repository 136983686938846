import React from "react";
import {Asset, Link} from "../../plugins/gatsby-plugin-atollon";
import {Logo, Back, RightArrow} from "./icons";
import PropertyCard from "./cards/property";
import StructuredText from "./structured-text/custom-structured-text-renderer";
import AgentCard from "./cards/agent";

const Header = ({pageContext}) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [direction, setDirection] = React.useState('down')

  let props = {
    onClick: () => {
      setOffCanvas(false)
    },
    activeClassName: 'active',
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  let headerClass = "header"
  if (scrolled || offCanvas) headerClass += " header--scrolled"
  if (offCanvas) headerClass += " header--off-canvas"
  if (sticky) headerClass += " header--sticky"
  if ( direction === 'down' && scrolled && !offCanvas ) headerClass += ' header--up'

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <div className='header__logo'>
            <Link {...props} to='/'><Logo/></Link>
          </div>

          <div className='header__nav'>
            <ul>
              <li><Link to='/for-sale/' {...props}>Property</Link></li>
              <li><Link to='/projects/' {...props}>Projects</Link></li>
              <li><Link to='/advocacy/' {...props}>Advocacy</Link></li>
              <li><Link to='/approach/' {...props}>Approach</Link></li>
              <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
              <li><Link to='/team/' {...props}>Team</Link></li>
            </ul>
          </div>

          <div className='header__contact'>
            <ul>
              <li><Link to='tel:+61295261111' {...props}>02 9526 1111</Link></li>
              <li><Link to='/contact/' {...props}>Contact</Link></li>
            </ul>
          </div>

          <div className='header__hamburger' onClick={() => setOffCanvas(!offCanvas)}>
            {offCanvas ? 'Close' : 'Menu'}
          </div>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas ? 'active' : ''}`}>
        <div className='off-canvas__inner'>
          <div className='off-canvas__nav'>
            <ul>
              <li><Link to='/for-sale/' {...props}>Property</Link></li>
              <li><Link to='/projects/' {...props}>Projects</Link></li>
              <li><Link to='/advocacy/' {...props}>Advocacy</Link></li>
              <li><Link to='/approach/' {...props}>Approach</Link></li>
              <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
              <li><Link to='/team/' {...props}>Team</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
              <li className='off-canvas__phone'><Link to='tel:+61295261111' className='btn btn--black' {...props}>02 9526 1111 <RightArrow /></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header