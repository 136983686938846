import React, { Component } from 'react'
import MarkerIcon from '../assets/images/marker.svg'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import greyStyles from '../utils/map-props'

class Map extends Component {

  shouldComponentUpdate() {
    return false
  }

  render() {
    // Don't render SSR
    if (typeof window === 'undefined') return (<section className='map' />)

    const { latitude, longitude } = this.props
    const location = { lat: latitude, lng: longitude }

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
      <GoogleMap
        defaultZoom={15}
        defaultCenter={location}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          rotateControl: false,
          clickableIcons: true,
          scrollwheel: false,
          streetViewControl: false,
          styles: greyStyles
        }}
      >
        <Marker
          position={ location }
          icon={{ url: MarkerIcon, size: {width: 20, height: 20}, anchor: {x: 10, y: 10}, scaledSize: {width: 20, height: 20} }}
        />
      </GoogleMap>
    ));

    return (
      <div className='map'>
        <div className='map__wrapper'>
          <MapWithAMarker
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDdZI8rUr_3o81JLUrOCja31R8MLJLBp_o&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div className={'map__element'} />}
          />
        </div>
      </div>
    )
  }
}

Map.defaultProps = {
  latitude: -34.042801631337426,
  longitude: 151.1233134497685,
  interstate: false,
}

export default Map