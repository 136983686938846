import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import {Link, Asset} from "../../../../plugins/gatsby-plugin-atollon";
import StructuredText from "../custom-structured-text-renderer";

const ColumnCard = ({items}) => {

  console.log(items)

    return (
        <section className='column-card'>
            <div className='column-card__inner'>
                {
                    items?.length > 0 &&
                    <div className='column-card__items'>
                        {
                            items.map((el, i) => (
                              <>
                              { el.link?.permalink &&
                                <Link to={el.link.permalink} key={i} className='column-card__item' data-sal='slide-up' data-sal-duration='1000'>
                                  {el.image && <Asset asset={el.image} />}
                                  {el.title && <h3>{parse(el.title)}</h3>}
                                  {el.richContent && <StructuredText data={el.richContent} />}
                                </Link>
                              }
                              { !el.link?.permalink &&
                                <div key={i} className='column-card__item' data-sal='slide-up' data-sal-duration='1000'>
                                  {el.image && <Asset asset={el.image} />}
                                  {el.title && <h3>{parse(el.title)}</h3>}
                                  {el.richContent && <StructuredText data={el.richContent} />}
                                </div>
                              }
                              </>
                            ))
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default ColumnCard

export const query = graphql`
fragment ColumnCardProps on DatoCmsColumnCard {
  ... on DatoCmsColumnCard {
    id
    items {
      image {
        ...InternalAssetProps
      }
      title
      link {
        slug
        permalink
      }
      richContent {
        value
      }
    }
  }
}
`