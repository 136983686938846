import React from 'react'
import Logo from '../assets/animations/logo-animation.json'
import Lottie from 'react-lottie'

const Loading = () => {

  const [stopped, setStopped] = React.useState(true)
  const [hide, setHide] = React.useState(false)

  const logo = {
    loop: false,
    autoplay: false,
    animationData: Logo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setStopped(false)
    }, 500)
    setTimeout(() => {
      setHide(true)
    }, 4000)
  }, [])

  return (
    <section className={`loading${hide ? ' loading--hide' : ''}`}>
      <Lottie
        options={logo}
        isClickToPauseDisabled={true}
        isStopped={stopped}
      />
    </section>
  )
}

export default Loading