import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import {Link, Asset} from "../../../../plugins/gatsby-plugin-atollon";
import {DownArrow} from "../../icons";

const PageHeaderIndex = ({title, subtitle, index, button, image}) => {
    return (
        <section className='page-header-index'>
            <div className='page-header-index__inner'>
                {title && <h2 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h2>}
                <div className='page-header-index__title' data-sal='slide-up' data-sal-duration='1000'>
                    <div className='page-header-index__button'>
                        {button?.length > 0 && button[0].link?.length > 0 &&
                            <Link
                                className="btn"
                                to={button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink}
                            >
                                {button[0].title}
                                <DownArrow/>
                            </Link>
                        }
                    </div>
                    {subtitle && <h3 data-sal='slide-up' data-sal-duration='1000'>{parse(subtitle)}</h3>}
                </div>

                <div className='page-header-index__links'>
                    {
                        index?.length > 0 &&
                        <ul>
                            {
                                index?.map((el, i) => (
                                    <li key={i} data-sal='slide-up' data-sal-duration='1000'>
                                        <Link to={el.link[0].externalLink ? el.link[0].externalLink : el.link[0].internalLink.permalink}>
                                            <span>{index?.length < 9 && '0'}{i + 1}</span>
                                            {el.title}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    }

                    {image && <div className='page-header-index__image' data-sal='slide-up' data-sal-duration='1000'><Asset asset={image} /></div>}
                </div>
            </div>
        </section>
    )
}

export default PageHeaderIndex

export const query = graphql`
  fragment PageHeaderIndexProps on DatoCmsPageHeaderIndex {
    ... on DatoCmsPageHeaderIndex {
      id
      title
      subtitle
      button {
        ...LinkWithTitleProps
      }
      index {
        ...LinkWithTitleProps
      }
      image {
        ...InternalAssetProps
      }
    }
  }
`