import React from "react"
import { Link, Asset } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"
import { StructuredText } from "react-datocms"
import FormComponent from "../../form"
import Map from "../../map"

const Contact = ({ title, image, richContent, phone, form, location, googleMap }) => {

    return (
      <section className='contact' id='contact'>
        <div className="contact__inner">
          { title && <h1 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h1> }
          { image && <Asset asset={image} /> }
          <div className='contact__wrapper'>
            <div className='contact__details' data-sal='slide-up' data-sal-duration='1000'>
              <h4>Contact Us</h4>
              { phone?.length > 0 && phone[0].link?.length > 0 &&
                <Link
                  className='contact__phone' 
                  to={phone[0].link[0].externalLink ? phone[0].link[0].externalLink : phone[0].link[0].internalLink.permalink}
                >
                  {phone[0].title}
                </Link>
              }
            </div>
            <div className='contact__content contact__content--desktop' data-sal='slide-up' data-sal-duration='1000'>
              { richContent && <StructuredText data={richContent} /> }
            </div>
          </div>
          <div className='contact__wrapper'>
            <div className='contact__details' data-sal='slide-up' data-sal-duration='1000'>
              <h4>Our Location</h4>
              { location && <div className='contact__address'><StructuredText data={location} /></div> }
            </div>
            <div className='contact__content contact__content--form'>
              <div className='contact__form-wrapper'>
                { form?.length > 0 && 
                  <div className='contact__form' data-sal='slide-up' data-sal-duration='1000'>
                    <FormComponent form={form[0].form} /> 
                  </div>
                }
                <div className='contact__content contact__content--mobile' data-sal='slide-up' data-sal-duration='1000'>
                  { richContent && <StructuredText data={richContent} /> }
                </div>
                <div className='contact__map' data-sal='slide-up' data-sal-duration='1000'>
                  <Map latitude={googleMap.latitude} longitude={googleMap.longitude} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Contact

export const query = graphql`
  fragment ContactProps on DatoCmsContact {
    ... on DatoCmsContact {
      id
      title
      image {
        ...InternalAssetProps
      }
      richContent {
        value
      }
      location {
        value
      }
      phone {
        ...LinkWithTitleProps
      }
      form {
        form {
          ...FormProps
        }
      }
      googleMap {
        latitude
        longitude
      }
    }
  }
`
