import React, {useRef} from 'react'
import { Link, Asset} from '../../../plugins/gatsby-plugin-atollon'
import { Sold } from "../icons";

const ProjectCard = (props) => {

  if (!props) return null

  return (
    <Link to={props.permalink} className={`card card--property${props.for === 'sold' ? ' card--sold' : ''}`} data-sal='slide-up' data-sal-duration='1000'>
      <div className='card__image'>
        {props.mainGallery?.length > 0 && (
          <Link to={props.permalink}>
            <Asset asset={props.mainGallery[0]}/>
          </Link>
        )}
        {props.for === 'sold' && <span className='card__tag'><Sold /></span>}
      </div>
      <div className='card__content'>
        <div className='card__title'>
          {props.address && props.suburb && props.postCode && <h4>
            <strong>{props.address}</strong><br/>
            {props.suburb} {props.postCode}
          </h4>}
        </div>
      </div>
    </Link>
  )
}

export default ProjectCard