import React from "react"
import { graphql } from "gatsby"
import Slider from "react-slick"
import StructuredText from '../custom-structured-text-renderer'
import parse from 'html-react-parser'

const Testimonial = ({ title, items }) => {

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    //fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  return (
    <section className='testimonial'>
      <div className='testimonial__inner'>
        <div className='testimonial__wrapper'>
          <div className='testimonial__title' data-sal='slide-up' data-sal-duration='1000'>
            <h2>{title}</h2>
          </div>
          { items && items.length > 0 &&
            <div className='testimonial__slider'>
              <Slider {...settings}>
                { items.map((item, index) => (
                  <div key={index} className='testimonial__item'>
                    <div className='testimonial__quote'>
                      <StructuredText data={item.quote} />
                    </div>
                    <div className='testimonial__author'>
                      { item.name && <p>{parse(item.name)}</p> }
                      { item.company && <p>{item.company}</p> }
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Testimonial

export const query = graphql`
  fragment TestimonialProps on DatoCmsTestimonial {
    ... on DatoCmsTestimonial {
      id
      title
      items {
        quote {
          value
        }
        name
        company
      }
    }
  }
`
