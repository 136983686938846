import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import StructuredText from "../custom-structured-text-renderer";
import FormComponent from "../../form";

const FormSelector = ({title, richContent, subContent, form}) => {
    return (
        <section className='form-selector' id='contact'>
            <div className='form-selector__inner'>
                {title && <h2 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h2>}

                <div className='form-selector__wrapper'>
                    {subContent && <div className='form-selector__subcontent' data-sal='slide-up' data-sal-duration='1000'><StructuredText data={subContent} /></div>}

                    <div className='form-selector__form' data-sal='slide-up' data-sal-duration='1000'>
                        {richContent && <div className='form-selector__content'><StructuredText as='h3' data={richContent} /></div>}
                        {form?.length > 0 && <FormComponent form={form[0].form} />}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FormSelector

export const query = graphql`
fragment FormSelectorProps on DatoCmsFormSelector {
  ... on DatoCmsFormSelector {
    title
    subContent {
      value
    }
    richContent {
      value
    }
    form {
      form {
        ...FormProps
      }
    }
  }
}
`