import React from "react"
import { Link } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"

const LandingBanner = ({ title, button, video }) => {

    return (
      <section className='landing-banner'>
        <div className="landing-banner__inner">
          <div className="landing-banner__title" data-sal='slide-up' data-sal-duration='1000'>
            { title &&
              <h1>{parse(title)}</h1>
            }
          </div>
          { button?.length > 0 && button[0].link?.length > 0 &&
            <Link 
              className="btn"
              to={button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink}
              data-sal='slide-up' data-sal-duration='1000'
            >
              {button[0].title}
              <RightArrow />
            </Link>
          }
        </div>
        { video && <video className='landing-banner__video' data-sal='slide-up' data-sal-duration='1000' src={video} autoPlay loop muted playsInline /> }
      </section>
    )
}

export default LandingBanner

export const query = graphql`
  fragment LandingBannerProps on DatoCmsLandingBanner {
    ... on DatoCmsLandingBanner {
      id
      title
      button {
        ...LinkWithTitleProps
      }
      video
    }
  }
`
