/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import React from 'react';
import LandingBanner from './blocks/landing-banner';
import KeyCallout from './blocks/key-callout';
import LatestListing from './blocks/latest-listing';
import Banner from './blocks/banner';
import Cta from './blocks/cta';
import Contact from './blocks/contact';
import DownloadCta from './blocks/download-cta';
import Testimonial from './blocks/testimonial';
import PropertyArchive from './blocks/property-archive';
import ProjectArchive from './blocks/project-archive';
import UpcomingArchive from './blocks/upcoming-archive';
import TeamArchive from './blocks/team-archive';
import ThankYou from './blocks/thank-you';
import PageHeaderIndex from "./blocks/page-header-index";
import ColumnCard from "./blocks/column-card";
import TitleColumnContent from "./blocks/title-column-content";
import PageHeader from "./blocks/page-header";
import FormSelector from "./blocks/form-selector";
import BasicPage from "./blocks/basic-page";
import EmptyBlock from './blocks/empty-block';
import { useDebug, DebugPill } from "../../../plugins/gatsby-plugin-atollon";

/*
* Context for retaining render depth
* */
export const BlockRendererContext = React.createContext(-1);

export const RenderBlock = ({ __typename, ...props }) => {
    switch (__typename.replace('DatoCms', '')) {
        case 'LandingBanner':
            return <LandingBanner {...props} />;
        case 'KeyCallout':
            return <KeyCallout {...props} />;
        case 'LatestListing':
            return <LatestListing {...props} />;
        case 'Banner':
            return <Banner {...props} />;
        case 'Cta':
            return <Cta {...props} />;
        case 'DownloadCta':
            return <DownloadCta {...props} />;
        case 'Testimonial':
            return <Testimonial {...props} />;
        case 'PropertyArchive':
            return <PropertyArchive {...props} />;
        case 'ProjectArchive':
            return <ProjectArchive {...props} />;
        case 'BasicPage':
            return <BasicPage {...props} />;
        case 'UpcomingArchive':
            return <UpcomingArchive {...props} />;
        case 'TeamArchive':
            return <TeamArchive {...props} />;
        case 'Contact':
            return <Contact {...props} />;
        case 'ThankYou':
            return <ThankYou {...props} />;
        case 'PageHeaderIndex':
            return <PageHeaderIndex {...props} />;
        case 'ColumnCard':
            return <ColumnCard {...props} />;
        case 'TitleColumnContent':
            return <TitleColumnContent {...props} />;
        case 'PageHeader':
            return <PageHeader {...props} />;
        case 'FormSelector':
            return <FormSelector {...props} />;
        default:
            return <EmptyBlock>{__typename}</EmptyBlock>;
    }
};

const BlockRenderer = ({ data, className }) => {
    const { showBlockNames } = useDebug();
    const existingRenderContextDepth = React.useContext(BlockRendererContext);

    return (
        <BlockRendererContext.Provider value={existingRenderContextDepth + 1}>
            {data && data.map((block) => {
                const { __typename: blockType } = block;

                if (!blockType) {
                    throw new Error("You must source __typename on every blocks field.")
                }

                return (
                    <>
                        {showBlockNames && (
                            <DebugPill style={{
                                position: 'absolute',
                            }}
                            >
                                {blockType}
                            </DebugPill>
                        )}
                        <RenderBlock
                            key={block.id}
                            className={className}
                            {...block}
                        />
                    </>
                );
            })}
        </BlockRendererContext.Provider>
    );
};

export default BlockRenderer;
