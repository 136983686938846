import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import '../assets/scss/main.scss'
import Loading from '../components/loading'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {

    const globalData = layoutQuery()
    const windowGlobal = typeof window !== 'undefined' && window

    // check local storage for animation
    const animation = windowGlobal.localStorage?.getItem('animation')

    return (
      <AtollonProvider {...props} globalData={globalData}>
        <Loading />
        <Header pageContext={props.pageContext} />
        <main>
            {props.children}
            <Footer/>
        </main>
      </AtollonProvider>
    )
}

export default Layout