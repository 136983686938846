import React from 'react'
import useProjectQuery from '../../../hooks/use-project-query'
import ProjectCard from '../../cards/project'
import { Link } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from 'gatsby'
//import SearchForm from '../../property-search'
import sal from 'sal.js'

const ProjectArchive = ({ title, propertyType }) => {

  // Data
  let properties = useProjectQuery()

  // State
  const [list, setList] = React.useState(properties)
  const [limit, setLimit] = React.useState(12)

  // sort properties for === sold at the end
  properties = properties.sort((a, b) => {
    if (a.for === 'sold' && b.for !== 'sold') return 1
    if (a.for !== 'sold' && b.for === 'sold') return -1
    return 0
  })

  let suburbs = []
  properties?.forEach((el, i) => {
    suburbs.push(el.suburb)
  })
  suburbs = suburbs.filter((v, i, a) => a.indexOf(v) === i)
  suburbs.sort()

  // Ref
  const load = React.createRef()

  // Events
  let timeout = null
  const onScroll = () => {
    const position = load.current?.getBoundingClientRect()
    if (!position) return
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      if (!timeout) {
        //if (!this.timeout && this.state.length < this.state.list) {
        timeout = setTimeout(() => {
          setLimit(limit + 6)
          timeout = null
        }, 10)
      }
    } else {
      clearTimeout(timeout)
    }
  }

  // Update
  React.useEffect(() => {
    sal()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [load])

  return (
    <>
      <section className='property-archive property-archive--project'>
        <div className='property-archive__inner'>
          <div className='property-archive__header'>
            <h1>Projects</h1>
          </div>
          { /*
          <div className='property-archive__content'>
            <div className='property-archive__count'>
              <p>{ list?.length } listings</p>
            </div> 
            <div className='property-archive__menu'>
              <ul>
                <li><Link to='/for-sale/' className={propertyType === 'sale' ? 'active' : ''}>Sale</Link></li>
                <li><Link to='/sold/' className={propertyType === 'sold' ? 'active' : ''}>Sold</Link></li>
                <li><Link to='/upcoming-inspections/'>Inspections</Link></li>
                <li><Link to='/upcoming-auctions/'>Auctions</Link></li>
              </ul>
            </div>
            <div className='property-archive__filter'>   
              <SearchForm
                list={properties || []} 
                set={setList} 
                suburbs={suburbs}
              />
            </div>
          </div>
          */}
          <div className='property-archive__items'>
            { list?.slice(0, limit).map((el) => {
              return <ProjectCard key={ el.id } type={ el.for } sold={ (el.for === 'sold') ? true : false } { ...el } />
            }) }
            { list?.length === 0 && <p className='properties__no-results'>No listings at this time.</p> }
          </div>
          <div ref={ load }/>
        </div>
      </section>
    </>
  )
}

export default ProjectArchive

export const query = graphql`
  fragment ProjectArchiveProps on DatoCmsProjectArchive {
    ... on DatoCmsProjectArchive {
      propertyType
    }
  }
`