import React from 'react'
import usePropertyQuery from '../../../hooks/use-property-query'
import PropertyCard from '../../cards/property'
import { Link } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from 'gatsby'
import SearchForm from '../../property-search'
import sal from 'sal.js'

const PropertyArchive = ({ title, propertyType }) => {

  // Data
  let properties = usePropertyQuery()
  properties = {
    'sale': properties.filter(property => property.for === 'sale'),
    'sold': properties.filter(property => property.for === 'sold'),
  }[propertyType]

  // State
  const [list, setList] = React.useState(properties)
  const [limit, setLimit] = React.useState(12)

  let suburbs = []
  properties?.forEach((el, i) => {
    suburbs.push(el.addressComponents[0].city)
  })
  suburbs = suburbs.filter((v, i, a) => a.indexOf(v) === i)
  suburbs.sort()

  // filter out `Bankstown, Barden Ridge, Croydon, Penshurst`
  suburbs = suburbs.filter(suburb => !['Bankstown', 'Barden Ridge', 'Croydon', 'Penshurst'].includes(suburb))


  // Ref
  const load = React.createRef()

  // if sold sort properties by soldDate
  if (propertyType === 'sold') {
    list.sort((a, b) => {
      return new Date(b.soldDate) - new Date(a.soldDate)
    })
  }

  // Events
  let timeout = null
  const onScroll = () => {
    const position = load.current?.getBoundingClientRect()
    if (!position) return
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      if (!timeout) {
        //if (!this.timeout && this.state.length < this.state.list) {
        timeout = setTimeout(() => {
          setLimit(limit + 6)
          timeout = null
        }, 10)
      }
    } else {
      clearTimeout(timeout)
    }
  }

  // Update
  React.useEffect(() => {
    sal()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [load])

  return (
    <>
      <section className='property-archive property-archive--property'>
        <div className='property-archive__inner'>
          <div className='property-archive__header'>
            <h1>Property</h1>
          </div>
          <div className='property-archive__content'>
            <div className='property-archive__count'>
              <p>{ list?.length } listings</p>
            </div> 
            <div className='property-archive__menu'>
              <ul>
                <li><Link to='/for-sale/' className={propertyType === 'sale' ? 'active' : ''}>Sale</Link></li>
                <li><Link to='/sold/' className={propertyType === 'sold' ? 'active' : ''}>Sold</Link></li>
                <li><Link to='/upcoming-inspections/'>Inspections</Link></li>
                <li><Link to='/upcoming-auctions/'>Auctions</Link></li>
              </ul>
            </div>
            <div className='property-archive__filter'>   
              <SearchForm
                list={properties || []} 
                set={setList} 
                suburbs={suburbs}
              />
            </div>
          </div>
          <div className='property-archive__items'>
            { list?.slice(0, limit).map((el) => {
              return <PropertyCard key={ el.id } type={ el.for } sold={ (el.for === 'sold') ? true : false } { ...el } />
            }) }
            { list?.length === 0 && <p className='properties__no-results'>No listings at this time.</p> }
          </div>
          <div ref={ load }/>
        </div>
      </section>
    </>
  )
}

export default PropertyArchive

export const query = graphql`
  fragment PropertyArchiveProps on DatoCmsPropertyArchive {
    ... on DatoCmsPropertyArchive {
      propertyType
    }
  }
`