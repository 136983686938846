import React from "react"
import { Link, Asset } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"
import StructuredText from "../custom-structured-text-renderer"

const KeyCallout = ({ richContent, items }) => {

    return (
      <section className='key-callout'>
        <div className='key-callout__inner'>
          <div className='key-callout__title' data-sal='slide-up' data-sal-duration='1000'>
            <StructuredText data={richContent} />
          </div>
          { items?.length > 0 &&
            <div className='key-callout__items'>
              { items.map((item, index) => (
                <div className='key-callout__item' key={index} data-sal='slide-up' data-sal-duration='1000'>
                  <Asset asset={item.image} />
                  { item.title && <h4>{parse(item.title)}</h4> } 
                  { item.richContent && <StructuredText data={item.richContent} /> } 
                  { item.link?.length > 0 &&
                    <Link 
                      className='key-callout__link'
                      to={item.link[0].permalink}
                    />
                  }
                </div>
              ))}
            </div>
          }
        </div>
      </section>
    )
}

export default KeyCallout

export const query = graphql`
  fragment KeyCalloutProps on DatoCmsKeyCallout {
    ... on DatoCmsKeyCallout {
      id
      richContent {
        value
      }
      items {
        image {
          ...InternalAssetProps
        }
        title
        richContent {
          value
        }
        link {
          permalink
        }
      }
    }
  }
`
