import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import {Asset} from "../../../../plugins/gatsby-plugin-atollon";
import StructuredText from "../custom-structured-text-renderer";

const PageHeader = ({title, richContent, image, h1}) => {
    return (
        <section className='page-header'>
            <div className='page-header__inner'>
                <div className='page-header__title'>
                    {title && h1 && <h1 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h1>}
                    {title && !h1 && <h2 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h2>}
                    <div className={`page-header__content${h1 ? ' page-header__content--h1' : ''}`} data-sal='slide-up' data-sal-duration='1000'>
                        {richContent && <StructuredText data={richContent}/>}
                    </div>
                </div>
            </div>
            {
                image &&
                <div
                    className='page-header__image' data-sal='slide-up' data-sal-duration='1000'>
                    <Asset asset={image}/>
                </div>
            }
        </section>
    )
}

export default PageHeader

export const query = graphql`
fragment PageHeaderProps on DatoCmsPageHeader {
  ... on DatoCmsPageHeader {
     title
     richContent {
        value
     }
     image {
        ...InternalAssetProps
     }
     h1
  }
}
`