import React from "react";
import {Asset, Link} from "../../../plugins/gatsby-plugin-atollon";

const AgentCard = ({ title, profileImage, name, permalink, jobTitle, phone, email }) => {

  return (
    <Link to={permalink} className='card card--agent' data-sal='slide-up' data-sal-duration='1000'>
      <div className='card__image'>
        {profileImage && <Asset asset={profileImage}/>}
      </div>
      <div className='card__title'>
        {(title || name) && <h4>{title || name}</h4>}
        {jobTitle && <p>{jobTitle}</p>}
      </div>
    </Link>
  )
}

export default AgentCard;