import React from "react";
import { graphql, useStaticQuery, navigate } from 'gatsby'
import {Link} from "../../plugins/gatsby-plugin-atollon";
import {Sun} from "./icons";
import FormComponent from './form'

const Footer = () => {

  const staticData = useStaticQuery(graphql`
    query DesktopFooterData {
      newsletterForm: datoCmsForm(title: {eq: "Subscribe"}) {
        ...FormProps
      }
    }
  `)

  return (
    <footer className='footer'>
      <div className='footer__top'>
        <div className='footer__inner'>
          <div className='footer__logo'>
            <Link to='/'>
              <Sun />
            </Link>
          </div>
          <div className='footer__nav'>
            <ul>
              <li><Link to='/for-sale/'>Property</Link></li>
              <li><Link to='/advocacy/'>Advocacy</Link></li>
              <li><Link to='/team/'>Team</Link></li>
              <li><Link to='/approach/'>Approach</Link></li>
              <li><Link to='/contact/'>Contact</Link></li>
            </ul>
          </div>
          <div className='footer__subscribe'>
            <h4>Stay in the loop</h4>
            <p>Receive our Market updates, exclusive listings, and property insights you won’t find anywhere else in Sutherland Shire.</p>
            <FormComponent form={ staticData.newsletterForm } />
          </div>
        </div>
      </div>
      <div className='footer__middle'>
        <div className='footer__inner'>
          <div className='footer__office'>
            <h4>Our Office</h4>
            <p>Suite 4, 305 Kingsway,
            Caringbah 2229 NSW Australia<br />
            <Link to='tel:+61295261111'>02 9526 1111</Link></p>
          </div>
          <div className='footer__social'>
            <h4>Social</h4>
            <ul>
              <li><Link to='https://www.facebook.com/locationrealestate' target='_blank' rel='noopener noreferrer'>Facebook</Link></li>
              <li><Link to='http://www.instagram.com/locationre' target='_blank' rel='noopener noreferrer'>Instagram</Link></li>
              <li><Link to='https://www.youtube.com/@locationrealestate' target='_blank' rel='noopener noreferrer'>YouTube</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer__bottom'>
        <div className='footer__inner'>
          <hr />
          <div className='footer__copyright'>
            <span>© { new Date().getFullYear() } Location Real Estate</span>
          </div>
          <div className='footer__links'>
            <Link to='/privacy-policy/'>Privacy Policy</Link>
          </div>
          <div className='footer__credit'>
            <Link target='_blank' rel='noopener noreferrer' to='https://atollon.com.au/'>Design by Atollon</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer