import React from 'react'
import useTeamMemberQuery from '../../../hooks/use-team-member-query'
import AgentCard from '../../cards/agent'
import { graphql } from 'gatsby'
import sal from 'sal.js'
import StructuredText from '../custom-structured-text-renderer'
import parse from 'html-react-parser'

const TeamArchive = ({ title, subtitle, richContent }) => {

  // Data
  let agents = useTeamMemberQuery()

  // State
  const [list, setList] = React.useState(agents)
  const [limit, setLimit] = React.useState(24)

  // Ref
  const load = React.createRef()

  // Events
  let timeout = null
  const onScroll = () => {
    const position = load.current?.getBoundingClientRect()
    if (!position) return
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      if (!timeout) {
        //if (!this.timeout && this.state.length < this.state.list) {
        timeout = setTimeout(() => {
          setLimit(limit + 6)
          timeout = null
        }, 10)
      }
    } else {
      clearTimeout(timeout)
    }
  }

  // Update
  React.useEffect(() => {
    sal()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [load])

  return (
    <>
      <section className='team-archive team-archive--property'>
        <div className='team-archive__inner'>
          <div className='team-archive__header'>
            {title && <h1 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h1> }
          </div>
          <div className='team-archive__content'>
            <div className='team-archive__subtitle' data-sal='slide-up' data-sal-duration='1000'>
              { subtitle && <p>{parse(subtitle)}</p> }
            </div>
            <div className='team-archive__text' data-sal='slide-up' data-sal-duration='1000'>
              { richContent && <StructuredText data={richContent} /> }
            </div>
          </div>
          <div className='team-archive__items'>
            { list?.slice(0, limit).map((el) => {
              return <AgentCard key={ el.id } { ...el } />
            }) }
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamArchive

export const query = graphql`
  fragment TeamArchiveProps on DatoCmsTeamArchive {
    ... on DatoCmsTeamArchive {
      title
      subtitle
      richContent {
        value
      }
    }
  }
`