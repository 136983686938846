import React from "react"
import { Link } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"

const Cta = ({ title, subtitle, button, link, linkTitle }) => {

    return (
      <section className='cta'>
        <div className="cta__inner">
          { title && <h3 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h3> }
          { subtitle && <h4 data-sal='slide-up' data-sal-duration='1000'>{parse(subtitle)}</h4> }
          { button?.length > 0 && button[0].link?.length > 0 &&
            <Link 
              className="btn btn--black"
              data-sal='slide-up' data-sal-duration='1000'
              to={button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink}
            >
              {button[0].title}
              <RightArrow />
            </Link>
          }
          { linkTitle && link &&
            <Link 
              className="btn btn--black"
              data-sal='slide-up' data-sal-duration='1000'
              to={link}
            >
              {linkTitle}
              <RightArrow />
            </Link>
          }
        </div>
      </section>
    )
}

export default Cta

export const query = graphql`
  fragment CtaProps on DatoCmsCta {
    ... on DatoCmsCta {
      id
      title
      subtitle
      button {
        ...LinkWithTitleProps
      }
    }
  }
`
