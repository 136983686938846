import React, { Component } from 'react'
import Select from 'react-select'

class SearchForm extends Component {

  state = {
    sub: null,
  }

  scrollToResults = (event) => {
    event.preventDefault()
    const cont = document.querySelector('.property-archive__items')
    const rect = cont.getBoundingClientRect()
    
    window.scrollTo({
      top: rect.top + 30,
      left: 0,
      behavior: 'smooth'
    });
  }

  handleSelectChange = (option, field) => {
    let state = {}
    switch (field?.action) {
      case 'select-option':
        state[field.name] = option?.value === null ? '' : option
        this.setState(state, this.updateList)
        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value
        this.setState(state, this.updateList)
        break
    }
  }

  updateList = () => {
    const { list, set, type } = this.props
    const { sub } = this.state

    let results = {}

    if (list) {
      results = list.filter((el, i) => {
        const { addressComponents } = el
        
        if (sub?.value && sub.value !== addressComponents[0].city ) return false
        return true
      })
    }
    set(results)
  }

  render() {
    let { sub } = this.state
    let { type, suburbs } = this.props
    let suburbsList = [{ label: "Show All", value: null }]

    suburbs?.map((el, i) => {
      suburbsList.push({
        label: el,
        value: el,
      })
      return true
    })
    
    return (
      <div className='search'>
        { suburbsList && suburbsList.length > 0 &&
          <div className='search__suburb'>
            <Select
              options={suburbsList}
              onChange={this.handleSelectChange}
              openMenuOnFocus={true}
              id="sub"
              name="sub"
              value={sub}
              placeholder="Filter by Suburb"
              className="react-select-container"
              classNamePrefix="react-select"
              ref={node => this.sub = node}
            />
          </div>
        }
      </div>
    )
  }
}

SearchForm.defaultProps = {
  list: [],
  set: () => {},
  suburbs: [],
}

export default SearchForm