import React, {Component} from 'react'
import parse from 'html-react-parser'
import {graphql} from "gatsby";
import StructuredText from "../custom-structured-text-renderer";

const BasicPage = ({title, richContent}) => {
  return (
    <section className='basic-page'>
      <div className='basic-page__inner'>
        {title && <h1>{parse(title)}</h1>}
        {richContent && <div className='basic-content__content'>{<StructuredText data={richContent}/>}</div>}
      </div>
    </section>
  )
}

export default BasicPage

export const query = graphql`
fragment BasicPageProps on DatoCmsBasicPage {
  ... on DatoCmsBasicPage {
    id
    title
    richContent {
      value
    }
  }
}
`