import React from "react"
import { Link } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"
import { RightArrow } from "../../icons"
import parse from "html-react-parser"
import StructuredText from "../custom-structured-text-renderer"

const ThankYou = ({ title, subtitle, richContent, button }) => {

    return (
      <section className='thank-you'>
        <div className='thank-you__inner'>
          <div className='thank-you__subtitle thank-you__subtitle--desktop'>
            { subtitle && <h4 data-sal='slide-up' data-sal-duration='1000'>{parse(subtitle)}</h4> }
          </div>
          <div className='thank-you__content'>
            { title && <h1 data-sal='slide-up' data-sal-duration='1000'>{parse(title)}</h1> }
            <div className='thank-you__subtitle thank-you__subtitle--mobile' data-sal='slide-up' data-sal-duration='1000'>
              { subtitle && <h4>{parse(subtitle)}</h4> }
            </div>
            { richContent && <StructuredText data={richContent} /> }
            { button?.length > 0 && button[0].link?.length > 0 &&
              <Link 
                className="btn btn--black"
                data-sal='slide-up' data-sal-duration='1000'
                to={button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink}
              >
                {button[0].title}
                <RightArrow />
              </Link>
            }
          </div>
        </div>
      </section>
    )
}

export default ThankYou

export const query = graphql`
  fragment ThankYouProps on DatoCmsThankYou {
    ... on DatoCmsThankYou {
      id
      title
      subtitle
      richContent {
        value
      }
      button {
        ...LinkWithTitleProps
      }
    }
  }
`
