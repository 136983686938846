import React from "react"
import { Asset } from "../../../../plugins/gatsby-plugin-atollon"
import { graphql } from "gatsby"

const Banner = ({ image }) => {

    return (
      <section className='banner'>
        <div className="banner__inner" data-sal='slide-up' data-sal-duration='1000'>
        { image && <Asset asset={image} /> }
        </div>
      </section>
    )
}

export default Banner

export const query = graphql`
  fragment BannerProps on DatoCmsBanner {
    ... on DatoCmsBanner {
      id
      image {
        ...InternalAssetProps
      }
    }
  }
`
