import { useStaticQuery, graphql } from "gatsby"

const useLatestListingsQuery = () => {
  const { sale, sold } = useStaticQuery(graphql`
    query LatestListings {
      sale: allDatoCmsProperty(
        filter: {for: {eq: "sale"}},
        sort: {marketingLiveDate: DESC}, 
        limit: 2
      ) {
        nodes {
          address
          addressComponents {
            buildingName
            city
            country
            level
            postCode
            region
            regionShort
            street
            streetNumber
            unit
          }
          allCarSpaces
          auctionDateTime
          beds
          baths
          market
          propertyType
          price
          priceSearch
          permalink
          title
          for
          images {
            all {
              ...MediaItemProps
            }
            primary {
              ...MediaItemProps
            }
          }
          slug
          soldDate
        }
      }
      sold: allDatoCmsProperty(
        filter: {for: {eq: "sold"}}, 
        sort: {soldDate: DESC},
        limit: 2
      ) {
        nodes {
          address
          addressComponents {
            buildingName
            city
            country
            level
            postCode
            region
            regionShort
            street
            streetNumber
            unit
          }
          allCarSpaces
          auctionDateTime
          beds
          baths
          market
          propertyType
          price
          priceSearch
          permalink
          title
          for
          images {
            all {
              ...MediaItemProps
            }
            primary {
              ...MediaItemProps
            }
          }
          slug
          soldDate
        }
      }
    }
  `)

  return { sale: sale.nodes, sold: sold.nodes }
}

export default useLatestListingsQuery