import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";
import {Asset} from "../../../../plugins/gatsby-plugin-atollon";
import StructuredText from "../custom-structured-text-renderer";

const TitleColumnContent = ({title, subtitle, hashId, image, leftContent, rightContent, fullWidthImage}) => {
    return (
        <section className='title-column-content' id={hashId}>
            <div className='title-column-content__inner'>
                <div className='title-column-content__heading'>
                    {subtitle && <h3 data-sal='slide-up' data-sal-duration='1000'>{parse(subtitle)}</h3>}
                    <div className='title-column-content__title' data-sal='slide-up' data-sal-duration='1000'>
                        {title && <h2><span>{parse(subtitle)}</span>{parse(title)}</h2>}

                        <div className='title-column-content__content' data-sal='slide-up' data-sal-duration='1000'>
                            {leftContent && <StructuredText data={leftContent} />}
                            {rightContent && <StructuredText data={rightContent} />}
                        </div>
                    </div>
                </div>

                {
                    image &&
                    <div className={`title-column-content__image ${fullWidthImage ? 'title-column-content__image--fullWidth' : ''}`} data-sal='slide-up' data-sal-duration='1000'>
                        <Asset asset={image} />
                    </div>
                }
            </div>
        </section>
    )
}

export default TitleColumnContent

export const query = graphql`
fragment TitleColumnContentProps on DatoCmsTitleColumnContent {
  ... on DatoCmsTitleColumnContent {
    id
    title
    subtitle
    hashId
    leftContent {
        value
    }
    rightContent {
        value
    }
    image {
        ...InternalAssetProps
    }
    fullWidthImage
  }
}
`