import { useStaticQuery, graphql } from 'gatsby'

const useProjectQuery = () => {
  const { allDatoCmsProject } = useStaticQuery(graphql`
    query ProjectQuery {
      allDatoCmsProject (
        filter: {hideForLocale: {ne: true}}
        sort: {position: ASC}
      ) {
        nodes {
          id
          address
          suburb
          postCode
          allCarSpaces
          beds
          baths
          permalink
          title
          for
          mainGallery {
            ...InternalAssetProps
          }
          slug
        }
      }
    }
  `)

  return allDatoCmsProject.nodes
}

export default useProjectQuery
